<template>
    <div>
        <table class="table table-hover" v-bind:id="id"  width="100%">
          <thead> </thead>
          <tbody> </tbody>
        </table>
    </div>
  </template>
  <script>
    export default {
      name:"TableLicenciaAlcoholes",
      props: {id:'',data_:[],lengthMenu:[]},
      mounted () {
        //$('#'+this.id).DataTable();
        //TableManaged2.init2();
        this.createTable();
        window.licAlcoholes = this;
      },      
      methods:{
        accionDetalle(dato){
          this.$emit('detalle', dato);
        },
        accionEliminar(dato){
          this.$emit('eliminar', dato);
        },
        createTable(){
          $('#sample_2').DataTable( {
            "data": this.data_,
            "columns": [
              {"data": "folio_id","title":"Folio ID"},
              {"data": "licencia","title":"Licencia"},
              {"data":"rfc","title":"RFC"},
              {"data": "nombre_comercial","title":"Nombre Comercial"},
              
              {"data":"municipio","title":"Municipio"},
              {"data":"ano_a_cobrar","title":"Año"},
              {"data":"estatus","title":"Estatus",
                "render": this.spanEstatus
              }, 
              {"data":"fecha_de_vencimiento","title":"Fecha de vencimiento"}, 
              {"data":"derecho","title":"Derecho"},
              {"data":"actualizacion","title":"Actualización"},
              {"data":"recargos","title":"Recargos"},
              {"data":"sancion","title":"Sanción"},
              {"data":"total","title":"Total"},
              {"render": this.btnDetalle },           
              {"render": this.btnEliminar },           
            ]
          });
        },
        btnDetalle( data, type, row, meta){
          return "<a class='btn btn-icon-only btn-circle green' data-toggle='modal' href='#portlet-edit' title='Detalle Registro'  onclick='accionDetalle("+JSON.stringify(row)+")'><i class='fa fa-list'></i></a>";
        },
        btnEliminar( data, type, row, meta){
          let btn="";
          if(row.estatus=="VIGENTE"){
            btn="<a class='btn btn-icon-only btn-circle red' data-toggle='modal' href='#portlet-del' title='Eliminar Registro'  onclick='accionEliminar("+JSON.stringify(row)+")'><i class='fa fa-trash-o'></i></a>";
          }
          
          return btn;
        },
        spanEstatus( data, type, row, meta){
          let status=row.estatus==='VIGENTE' ? 'btn-success' : row.estatus==='REFERENCIA ACTIVA' ? 'btn-warning' : row.estatus==='PAGADO' ? 'label-default' : 'label-danger'
          return '<span class="label  btn-circle '+status+'" class="">'+row.estatus+' </span>';
        }
      }   
    }
  </script>