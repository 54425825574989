/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');
import Vuetify from 'vuetify';

window.Vue = require('vue');
Vue.use(Vuetify);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('chart-bancos', require('./components/ChartBancos.vue').default);
Vue.component('chart-horizontal', require('./components/ChartHorizontal.vue').default);
Vue.component('chart-entidad', require('./components/ChartEntidades.vue').default);
Vue.component('table-sample', require('./components/ReporteEstadistica.vue').default);
Vue.component('revision-documentos-alcoholes', require('./components/RevisionDocumentosAlcoholes.vue').default);
Vue.component('cargar-excel-alcoholes', require('./components/CargaRefrendoAlcoholes.vue').default);
Vue.component('bitacora-alcoholes', require('./components/RefrendoAlcoholesBitacora.vue').default);
Vue.component('administrar-usuarios', require('./components/AdministrarUsuarios.vue').default);
Vue.component('servicio-catastro', require('./components/General/CatastroComponent.vue').default);
Vue.component('rec-consultas', require('./components/RecConsulta.vue').default);
Vue.component('licencia-alcoholes-carga', require('./components/LicenciaAlcoholesCarga.vue').default);
Vue.component('licencia-alcoholes-bitacora', require('./components/LicenciaAlcoholesBitacora.vue').default);
Vue.component('licencia-alcoholes-revision', require('./components/LicenciaAlcoholesRevision.vue').default);
Vue.component('licencia-alcoholes-eliminados', require('./components/LicenciaAlcoholesEliminados.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.filter("chartTooltips", function(t,d,sim) {
	let simbPeso="";
    if (sim=='1'){
    	simbPeso="$"
    }
    var xLabel = d.datasets[t.datasetIndex].label;
	var yLabel = t.yLabel >= 1000 ? simbPeso + t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : simbPeso + t.yLabel;
	return xLabel + ': ' + yLabel;
});
Vue.filter("chartTick", function(value,sim) {
	let simbPeso="";
    if (sim=='1'){
    	simbPeso="$"
    }
    value=Math.round(value *100)/(100)
    if (parseInt(value) >= 1000) {
	 return simbPeso + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	} else {
	 return simbPeso + value;
	}
});
Vue.filter("mesesConvert", function(value) {
	let meses = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
    if (value >= 1 && value <= 12) {
        return meses[value - 1]; 
    }else{
        return "Número de mes inválido";
    }
});
Vue.filter("saveCSV", function(JSONData,encabezado,reportTitle) {
	var f = new Date();
        fecha =  f.getFullYear()+""+(f.getMonth() +1)+""+f.getDate()+""+f.getHours()+""+f.getMinutes()+""+f.getSeconds()+"_";
        var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;    
        var CSV = '';    
        CSV += encabezado + '\r\n';
         for (var i = 0; i < arrData.length; i++) {
            var row = "";
            for (var index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }console.log(row);
            row.slice(0, row.length - 1); 
            CSV += row + '\r\n';
        }
        if (CSV == '') {        
            alert("Invalid data");
            return;
        }
        var fileName = fecha;
        fileName += reportTitle.replace(/ /g,"_");
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
        var link = document.createElement("a");    
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        Command: toastr.success("Success", "Notifications")
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
});
Vue.filter("loadingCharge", function(value) {
	if(value){
        Metronic.blockUI({ target: '.blockui_sample_3_2_element',message: 'Cargando...', boxed: true});
    }else{
        Metronic.unblockUI('.blockui_sample_3_2_element'); 
    }
});
Vue.filter("generaPassword", function(mayusL,minusL,numbersL) {
    var j, x, i;
    var result        = '';
    var lettersMayus  = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    var letterMinus   = 'abcdefghijklmnñopqrstuvwxyz';
    var numbers       = '0123456789';
    for (i = 0; i < mayusL; i++ ) {
        result += lettersMayus.charAt(Math.floor(Math.random() * lettersMayus.length));
    }
    for (i = 0; i < minusL; i++ ) {
        result += letterMinus.charAt(Math.floor(Math.random() * letterMinus.length));
    }
    for (i = 0; i < numbersL; i++ ) {
        result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
    result = result.split("");
    for (i = result.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = result[i];
        result[i] = result[j];
        result[j] = x;
    }
    result = result.join("");
    return result
});
const app = new Vue({
    vuetify: new Vuetify(),
    el: '#app'
    
});
