<template>
    <div >
      <portlet :clase="'container col-md-12 blockui_sample_3_2_element'" > 
        <template slot="actions">
            <a class="btn btn-circle btn-icon-only btn-success" :href="link_afectacion" target="_blank" title='Descargar reporte de afectación de pagos' ><i class="fa fa-dollar"></i></a> 
            <a class="btn btn-circle btn-icon-only btn-success" title='Recalcular' v-on:click="loadCalculo()"><i class="fa fa-calculator"></i></a>
            <a class="btn btn-circle btn-icon-only btn-success" :href="link_download" data-toggle='modal' title='Descargar Excel' ><i class="fa fa-file-excel-o"></i></a>
            <a class="btn btn-circle btn-icon-only btn-warning" href="#portlet-mensaje" data-toggle='modal' title='Mensaje de carga' v-on:click="consultaControl()"><i class="fa fa-exclamation"></i></a>
            <a class="btn btn-circle btn-icon-only btn-default" href="#portlet-upload-doc" data-toggle='modal' title='Carga base de datos' v-on:click="verificaCarga()"><i class="fa fa-cloud-upload"></i></a>          
        </template>
        <template slot="caption">
          <div class="row"> 
            <div class='col-md-6'>          
                <span class="caption-subject bold uppercase"> 
                    <i class="fa fa-database"></i> Cargar registros 
                    &nbsp;<a class="btn btn-circle btn-icon-only btn-default" title='Recargar registros' v-on:click="loadTableSample()"><i class="fa fa-rotate-left"></i></a>
                </span>         
            </div>
           <h5><div class='col-md-1 text-center'><span class="label btn-warning btn-circle">&nbsp;&nbsp;</span>REFERENCIA ACTIVA</div>
           <div class='col-md-1  text-center'><span class="label label-default btn-circle">&nbsp;&nbsp;</span>PAGADO</div>
          <div class='col-md-1 text-center'><span class="label btn-success btn-circle">&nbsp;&nbsp;</span>VIGENTE</div> </h5>           
          </div>  
        </template>      
        <template slot="body">         
          <div class="row">           
            <tableSample :lengthMenu="lengthMenu" @detalle="editarRegistro" @eliminar="eliminarRegistro"  :data_="data_" :id="'sample_2'" v-if="renderTable"/>  
          </div>
        </template>
      </portlet>
      <modalconfirmation :id="'portlet-upload-doc'" >      
        <template slot="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
            <h4 class="modal-title"><i class="fa fa-cloud-upload"></i> Cargar base de datos</h4>
        </template>
        <template slot="modal-body">
            <div v-if="carga_archivo">
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-warning alert-dismissable"><strong>La última carga finalizó: </strong> {{ fecha_carga }}
                        </div>
                    </div>
                </div> 
                <span class="help-block">&nbsp;</span>        
                <div class="fileinput fileinput-new" data-provides="fileinput">                       
                    <span class="btn green btn-file">
                    <span class="fileinput-new">
                    <i class="fa fa-plus"></i>&nbsp; &nbsp;Adjuntar Archivo </span>
                    <span class="fileinput-exists">
                    <i class="fa fa-exchange"></i>&nbsp; &nbsp;Cambiar Archivo </span>
                    <input type="file" name="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="file" v-on:change="handleFileUpload( $event )">
                    </span>
                    <button type="submit" class="btn blue fileinput-exists enableBtn" v-on:click="saveFile()"><i class="fa fa-cloud-upload"></i> &nbsp;Guardar</button>
                    <span class="fileinput-filename" style="text-overflow: ellipsis;width: 240px;overflow: hidden; white-space: nowrap;">
                    </span>&nbsp; <a href="javascript:;" class="close fileinput-exists" data-dismiss="fileinput" id="delFile">
                    </a>               
                </div>
            </div>
            <div v-if="!carga_archivo">
                <div class="row">
                    <span class="help-block">&nbsp;</span>
                    <div class="col-md-12">
                        <div class="alert alert-warning alert-dismissable">
                            <center> <h4>El archivo está siendo procesado. </h4> </center>
                        </div>
                    </div>
                    <span class="help-block">&nbsp;</span>
                </div>
            </div>
            
        </template>
        <template slot="modal-footer">
          <button type="button" data-dismiss="modal" class="btn default" v-on:click="closeClear()">Cerrar</button>
        </template>
      </modalconfirmation>
      <modal :id="'portlet-edit'" :modal="'modal-lg'">      
        <template slot="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" v-on:click="closeClear()"></button>
            <h4 class="modal-title"><i class="fa fa-cogs"></i> Administración de base de datos</h4>
        </template>
        <template slot="modal-body">
            <div class="row">
                <portlet :clase="'container col-md-12'" > 
                    <template slot="tools">
                        <a href="javascript:;" class="collapse"></a>
                    </template>
                    <template slot="caption">
                        <h4><strong>Detalles</strong></h4>
                    </template>
                    <template slot="body" :style="'display: none;'">
                        <div class="row">
                            <div id="addDetalles" v-for="( d  , index ) in detalle_">
                                <div v-if="index != 'id' && index != 'updated_at' && index != 'created_at'  && index != 'estatus'  && index != 'tipo_de_pago'  && index != 'folio_de_pago'"  class='col-md-6'>
                                    <div class='form-group'>
                                        <label><strong>{{index=="ano_a_cobrar" ? "AÑO A COBRAR" :index.replaceAll("_", " ").toUpperCase()}}</strong></label>
                                        <br><label>{{d}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </template>
                </portlet> 
            </div>
            <div class="row">
                <portlet :clase="'container col-md-12 blockui_sample_3_2_element'" > 
                    <template slot="tools">
                        <a href="" class="collapse" data-original-title="" title=""></a>
                    </template>
                    <template slot="caption">
                        <h4><strong>Actualizar registro</strong></h4>
                    </template>
                    <template slot="body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="estatus">ESTATUS</label>
                                    <select2 class="form-control" :options="options" v-model="estatus" :disabled="inputDisable==true" @eventchange="onchageStatus">
                                        <option v-for="b in data_estatus" :value="b.value">{{b.name}}</option>
                                    </select2>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="tipo_de_pago">TIPO DE PAGO</label>
                                    <select2 class="form-control" :options="options" v-model="tipo_de_pago" :disabled="inputDisable==true">
                                        <option v-for="b in data_tipo" :value="b.value">{{b.name}}</option>
                                    </select2>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="folio_de_pago">FOLIO DE PAGO</label>
                                    <input type="text" class="form-control" name="folio_de_pago" v-model="folio_de_pago" placeholder="FOLIO DE PAGO" :disabled="inputDisable==true">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                   <button class="btn btn-green" v-on:click="updateRegistro()" :disabled="inputDisable==true"><i class="fa fa-check"></i> Actualizar</button>
                                </div>
                            </div>
                        </div>                            
                    </template>
                </portlet>
            </div>
        </template>
        <template slot="modal-footer">
          <button type="button" data-dismiss="modal" class="btn default" v-on:click="closeClear()">Cerrar</button>
        </template>
      </modal>
      <modal :id="'portlet-mensaje'" :modal="'modal-lg'">      
        <template slot="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
            <h4 class="modal-title"><i class="fa fa-cogs"></i> Mensaje de carga</h4>
        </template>
        <template slot="modal-body">
            <div class="row">
                <div class='col-md-12'> 
                    <label for="titulo">Titulo</label>
                    <input type="text" class="form-control col-md-12" name="titulo" id="titulo" v-model="titulo" placeholder="Titulo">
                </div>
                <div class='col-md-12'> 
                    <label for="mensaje">Mensaje</label>
                    <textarea class="form-control col-md-12" name="mensaje" id="mensaje" v-model="mensaje" rows="3" placeholder="Escribe el mensaje..."></textarea>
                </div>
                <div class='col-md-12'>
                    <span class="help-block">&nbsp;</span>
                    <button class="btn green" v-on:click="guardarControl()"><i class="fa fa-check"></i> Guardar</button>
                </div>
            </div>
        </template>
        <template slot="modal-footer">
          <button type="button" data-dismiss="modal" class="btn default">Cerrar</button>
        </template>
      </modal>
      <modalconfirmation :id="'portlet-del'" >      
            <template slot="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                <h4 class="modal-title">Confirmar</h4>
            </template>
            <template slot="modal-body">
               <h5> DETALLE DEL REGITRO A ELIMINAR:</h5>
                <p><strong>Folio ID:</strong> {{folio_id}}</p>
                <p><strong>Licencia:</strong> {{licencia}}</p>
                <p><strong>RFC:</strong> {{rfc}}</p>
                <p><strong>Nombre Comercial:</strong> {{nombre_comercial}}</p>
                <p><strong>Municipio:</strong> {{municipio}}</p>
                    <div class="form-group">
                        <label for="motivo" class="text-blob"><strong>Motivo:</strong></label>
                        <select2 class="form-control" :options="options" v-model="motivo" >
                            <option v-for="b in data_motivo" :value="b.value">{{b.name}}</option>
                        </select2>
                    </div>
                    <div class="form-group">
                    <label for="" class="text-blob"><strong>Documento soporte:</strong></label>
                    <span class="help-block">&nbsp;</span>
                    <div class="fileinput fileinput-new" data-provides="fileinput">                       
                        <span class="btn green btn-file">
                        <span class="fileinput-new">
                        <i class="fa fa-plus"></i>&nbsp; Adjuntar Archivo</span>
                        <span class="fileinput-exists">
                        <i class="fa fa-exchange"></i>&nbsp; &nbsp;Cambiar Archivo </span>
                        <input type="file" name="file" accept="application/pdf" id="file" v-on:change="handleFileUpload( $event )">
                        </span>
                        <span class="fileinput-filename" style="text-overflow: ellipsis;width: 240px;overflow: hidden; white-space: nowrap;">
                        </span>&nbsp; <a href="javascript:;" class="close fileinput-exists" data-dismiss="fileinput"id="delFilePDF">
                        </a>          
                    </div>
                </div>
            </template>
            <template slot="modal-footer">
                <button type="button" data-dismiss="modal" class="btn default" v-on:click="cancelarEliminar()">Cacelar </button>
                <button type="button" class="btn green" v-on:click="eliminaLicencia()"><i class="fa fa-check"></i> Aceptar</button>
            </template>
        </modalconfirmation>
    </div>
  </template>  
  <script>
  import axios from 'axios'
  import select2 from './General/Select.vue'
  import portlet from './General/Portlet.vue'
  import modal from './General/Modal.vue'
  import modalconfirmation from './General/ModalConfirmation.vue'
  import tableSample from './Individual/TableLicenciaAlcoholes.vue'
  import VCalendar from 'v-calendar'
  export default {
    name: 'ReporteEstadistica',
    components: {axios,select2,portlet,modal,modalconfirmation,tableSample,VCalendar},
    data(){
        return{
            lengthMenu:[[10, 25, 50, -1],[10, 25, 50, 'All']],
            file:'',
            data_:[],
            options: [],
            render:'',
            dato:[],
            detalle_:[],
            estatus:'',
            options:[],
            id_registro:'',
            folio_id:'',
            licencia:'',
            rfc:'',
            nombre_comercial:'',
            folio_de_pago:'',
            municipio:'',
            tipo_de_pago:'',
            renderTable:false,
            inputDisable:false,
            inputDisableVigencia:false,
            styleVigencia:"",
            link_download:`${process.env.APP_URL}/licencia-alcoholes-download` ,
            link_afectacion:`${process.env.APP_URL}/licencia-alcoholes-afectacion` ,
            carga_archivo:false,
            fecha_carga:'',
            titulo:'',
            mensaje:'',
            file:'',
            motivo:'',
            data_estatus:[
                //{"value":"VIGENTE","name":"VIGENTE"},
                {"value":"","name":"----selecciona----"},
                {"value":"PAGADO","name":"PAGADO"}
                //{"value":"REFERENCIA ACTIVA","name":"REFERENCIA ACTIVA"}
            ],
            data_tipo:[
                {"value":"","name":"----selecciona----"},
                {"value":"EN LÍNEA","name":"EN LÍNEA"},
                {"value":"PRESENCIAL","name":"PRESENCIAL"}
            ],
            data_motivo:[
                {"value":"","name":"----selecciona----"},
                {"value":"Corrección de datos","name":"Corrección de datos"},
                {"value":"Cancelación de Licencia","name":"Cancelación de Licencia"},
                {"value":"Revocación de Licencia","name":"Revocación de Licencia"}
            ],
        }
    },
    methods:{
        handleFileUpload(event){
            this.file = event.target.files[0];
        },
        verificaCarga(){
            this.carga_archivo=false;
            this.fecha_carga='';             
            axios.get(`${process.env.APP_URL}/licencia-alcoholes-verifica`)
                .then((response)=>{
                    let data_a=response.data.response;
                    //console.log(typeof(response.data.response[0]));
                    if(response.data.status=="200"){
                        if(response.data.response[0]["status"]==0){
                            this.carga_archivo=false;
                            this.fecha_carga='';
                        }else{
                            this.carga_archivo=true;
                            this.fecha_carga=response.data.response[0]["fecha"];
                        }                        
                    }else{
                        this.carga_archivo=true;
                        this.fecha_carga='';
                    }
                })
                .catch(error=>{
                    console.log(error);
                    this.carga_archivo=true;              
                });
        },
        loadTableSample(){
            this.renderTable = false 
            this.loagingC(true);
            this.data_=[];                  
            axios.get(`${process.env.APP_URL}/licencia-alcoholes-padron`)
                .then((response)=>{
                    this.data_=response.data.response;
                    this.renderTable = true
                    this.loagingC(false);
                })
                .catch(error=>{
                    console.log(error);                    
                    this.loagingC(false);                    
                });
        },
        loadCalculo(){                 
            axios.get(`${process.env.APP_URL}/licencia-alcoholes-calculo`)
                .then((response)=>{
                    this.notifications(response.data.status,response.data.message);
                })
                .catch(error=>{
                    console.log(error);
                    this.notifications("400","Error al ejecutar");
                });
        },
        saveFile(){
            //this.renderTable = false
            if(this.file.length==0){this.notifications("400","Archivo, requerido!"); return false;}
            let formData = new FormData();
            formData.append('fileExcel', this.file);
            axios.post(`${process.env.APP_URL}/licencia-alcoholes-import`,
                formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response)=>{
                    //this.renderTable = true
                    this.notifications(response.data.status,response.data.message);
                    this.closeClear();
                    this.carga_archivo=false;
                    this.fecha_carga='';
                })
                .catch(error=>{
                    console.log(error);
                }
            );
        },
        onchageStatus(){
            if(this.estatus=="VIGENTE"){
                this.inputDisableVigencia=false;
            }else{
                this.inputDisableVigencia=true;
            }
        },
        downloadFile(){           
            axios.get(`${process.env.APP_URL}/licencia-alcoholes-download`)
                .then((response)=>{           
            
                })
                .catch(error=>{
                    this.notifications("400","Error al descargar");
                    console.log(error);
                }
            );
        },
        editarRegistro(dato){
            this.id_registro=dato.id;
            //if(dato.estatus == 'PAGADO' || dato.estatus =='REFERENCIA ACTIVA'){
                this.addTipoPago(dato.estatus);
           // }else{this.addTipoPago(false);}
            this.detalle_=dato;
            setTimeout(() => {
                this.tipo_de_pago=dato.tipo_de_pago == null ? '' : dato.tipo_de_pago; 
                this.estatus=dato.estatus == null ? '' : dato.estatus;
                this.folio_de_pago= dato.estatus=="REFERENCIA ACTIVA" ? '' : dato.folio_de_pago == null ? '' : dato.folio_de_pago;
            }, 200);
            this.$forceUpdate();
            if(dato.estatus=="PAGADO" || dato.estatus=="REFERENCIA ACTIVA" ){
                this.inputDisable=true;
            }else{
                this.inputDisable=false;
                this.styleVigencia="";
            }

        },
        addTipoPago(tipo){
            console.log(typeof(this.data_estatus[3]));
            if(tipo=="PAGADO"){
                if(typeof(this.data_estatus[3])=='undefined'){
                    this.data_estatus.push( {"value":"REFERENCIA ACTIVA","name":"REFERENCIA ACTIVA"});
                    this.data_tipo.push({"value":"TRAMITES NL","name":"TRAMITES NL"});
                }
            }else{
                if(typeof(this.data_estatus[2])!=='undefined'){
                    this.data_estatus.splice(3, 1);
                    this.data_estatus.splice(2, 1);
                    this.data_tipo.splice(3, 1);
                }
            }
        },
        updateRegistro(){
            if(this.inputDisable){  return; }
            if(this.estatus=="PAGADO"){
                if(this.tipo_de_pago.length==0){
                    this.notifications("400","Tipo de Pago, Requerido!");
                    return;
                }
                if(this.folio_de_pago.length==0){
                    this.notifications("400","Folio Pago, Requerido!");
                    return;
                }                
            }
            if(this.estatus!="PAGADO"){
                this.notifications("400","Estatus, Requerido!");
                return;                
            }
            if(this.tipo_de_pago=="TRAMITES NL"){
                this.notifications("400","Tipo de Pago,No Valido!");
                return;
            }
            axios.post(`${process.env.APP_URL}/licencia-alcoholes-update`,{
                id:this.id_registro,
                tipo_de_pago:this.tipo_de_pago,
                folio_de_pago:this.folio_de_pago,
                estatus:this.estatus
                })
                .then((response)=>{
                    this.notifications(response.data.status,response.data.message);
                    if(response.data.status=="200"){
                        this.loadTableSample();
                    }
                })
                .catch(error=>{
                    console.log(error);
                }
            );
        },
        eliminarRegistro(dato){
           this.folio_id=dato.folio_id;
           this.licencia=dato.licencia;
           this.rfc=dato.rfc;
           this.nombre_comercial=dato.nombre_comercial;
           this.municipio=dato.municipio;
           this.id_registro=dato.id;
        },
        fileClear(){
            $("#delFilePDF").click();
        },
        cancelarEliminar(){
            this.motivo="";
            this.fileClear();
        },
        eliminaLicencia(){
            if(this.file.length==0){this.notifications("400","Archivo, requerido!"); return false;}
            if(this.motivo==""){this.notifications("400","Motivo, requerido!"); return false;}
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('id', this.id_registro);
            formData.append('motivo', this.motivo);
            axios.post(`${process.env.APP_URL}/licencia-alcoholes-delete`,
            formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response)=>{
                    this.notifications("200",response.data.message);
                    this.loadTableSample();
                    this.motivo="";
                    this.fileClear();
                    $("#portlet-del").modal('hide');
                })
                .catch(error=>{
                    console.log(error);
                    this.notifications("400","error al eliminar");
                }
            );
        },
        consultaControl(){                
            axios.get(`${process.env.APP_URL}/licencia-alcoholes-control`)
                .then((response)=>{
                    let data=response.data.response;
                    this.titulo=data[0].titulo;
                    this.mensaje = data[0].mensaje;
                })
                .catch(error=>{
                    console.log(error);                    
                    this.titulo="";
                    this.mensaje ="" ;                 
                });
        },
        guardarControl(){   

            axios.post(`${process.env.APP_URL}/licencia-alcoholes-control`,{
                titulo: this.titulo,
                mensaje:this.mensaje
            })
            .then((response)=>{
                this.notifications("200",response.data.message);
            })
            .catch(error=>{
                console.log(error);                    
                this.notifications("400",response.data.message);               
            });
        },
        cerrarModal(){
            this.id_registro='';
        },
        closeClear(){
            //document.getElementById('delFile').click();
            $("#delFile").click();
        },
        fmoneda(value, tipo, values) {
            return Vue.filter('chartTick')(value,tipo);
        },
        loagingC(value, index, values) {
            return Vue.filter('loadingCharge')(value);
        },
        notifications(Code,Message){
            if(Code=="200"){
                Command: toastr.success(Message, "Notifications");
            }else{
                Command: toastr.warning(Message, "Notifications");
            }
        }
    },
    mounted() {
        this.loadTableSample();
    } 
  }
  </script>