<template>
    <div >
        <portlet :clase="'container col-md-12'">
            <template slot="actions"> </template>
            <template slot="caption">
                <div class="row"> 
                <div class='col-md-6'>          
                    <span class="caption-subject bold uppercase"><i class="fa fa-search"></i> Filtros</span>          
                </div> 
                </div>  
            </template>
            <template slot="caption2">
                <div class="row">	
                    <div class="col-md-4 col-ms-12">
                    <div class="form-group">
                        <label >Rango de Fechas</label>
                        <v-date-picker v-model="range" is-range >
                            <template v-slot="{ inputValue, inputEvents }">
                                <div class="flex justify-center items-center input-group" >
                                <span class='input-group-addon'>De</span>
                                <input :value="inputValue.start" v-on="inputEvents.start" class="form-control  rounded border rounded"/>
                                <span class='input-group-addon'>A</span>
                                <input :value="inputValue.end" v-on="inputEvents.end" class="form-control  rounded border rounded green" />
                                </div>
                            </template>
                        </v-date-picker> 
                    </div>
                </div>	   
                <div class="col-md-3 col-ms-12" >
                    <span class="help-block">&nbsp;</span>
                    <button type="button" class="btn green enableBtn" id="btnbuscar" v-on:click="loadTableSample()" >Buscar</button>
                </div>            
                </div> 
            </template>
        </portlet>
      <portlet :clase="'container col-md-12 blockui_sample_3_2_element'" > 
        <template slot="actions"> 
            <a class="btn btn-circle btn-icon-only btn-success" :href="urlDates()" target="_blank" title='Descargar Excel' ><i class="fa fa-file-excel-o"></i></a>
        </template>
        <template slot="caption">
          <div class="row"> 
            <div class='col-md-6'>          
                <span class="caption-subject bold uppercase"> 
                    <i class="fa fa-database"></i> registros
                </span>         
            </div>
          </div>  
        </template>      
        <template slot="body">         
          <div class="row">           
            <tableSample :lengthMenu="lengthMenu" @detalle="detalleRegistros" :data_="data_" :id="'sample_2'" v-if="renderTable"/>  
          </div>
        </template>
      </portlet>
      <modal :id="'portlet-edit'" :modal="'modal-lg'">      
        <template slot="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" v-on:click="closeClear()"></button>
            <h4 class="modal-title"><i class="fa fa-cogs"></i> Administración de base de datos</h4>
        </template>
        <template slot="modal-body">
            <div class="row">
                <portlet :clase="'container col-md-12'" > 
                    <template slot="tools">
                        <a href="javascript:;" class="collapse"></a>
                    </template>
                    <template slot="caption">
                        <h4><strong>Detalles</strong></h4>
                    </template>
                    <template slot="body" :style="'display: none;'">
                        <div class="row">
                            <div id="addDetalles" v-for="( d  , index ) in detalle_">
                                <div v-if="index != 'id' && index != 'updated_at' && index != 'archivo_nombre' && index != 'json_registro' && index != 'created_at'  && index != 'estatus'  && index != 'tipo_de_pago'  && index != 'folio_de_pago'"  class='col-md-6'>
                                    <div class='form-group'>
                                        <label><strong>{{ index=="ano_a_cobrar" ? "AÑO A COBRAR" : index.replaceAll("_", " ").toUpperCase()}}</strong></label>
                                        <br><label>{{d}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </template>
                </portlet> 
            </div>
        </template>
        <template slot="modal-footer">
          <button type="button" data-dismiss="modal" class="btn default" v-on:click="closeClear()">Cerrar</button>
        </template>
      </modal>
    </div>
  </template>  
  <script>
  import axios from 'axios'
  import select2 from './General/Select.vue'
  import portlet from './General/Portlet.vue'
  import modal from './General/Modal.vue'
  import modalconfirmation from './General/ModalConfirmation.vue'
  import tableSample from './Individual/TableLicenciaAlcoholesEliminados.vue'
  import VCalendar from 'v-calendar'
  export default {
    name: 'LicenciaAlcoholesEliminados',
    components: {axios,select2,portlet,modal,modalconfirmation,tableSample,VCalendar},
    data(){
        return{
            tipo_busqueda:'0',
            options:[],
            data_tipo:[],
            data_:[],
            detalle_:[],
            url_excel:`${process.env.APP_URL}/licencia-alcoholes-eliminados`,
            range: {
                start:this.firtsDate(),
                end: new Date(),
            },
            lengthMenu:[],
            id_registro:'',
            renderTable:false,
        }
    },
    methods:{
        loadTableSample(){
            this.renderTable=false;
            this.loagingC(true);
            this.data_=[];                  
            axios.post(`${process.env.APP_URL}/licencia-alcoholes-eliminados`,{
                incio: new Date(this.range.start).toISOString().slice(0,10),
                fin: new Date(this.range.end).toISOString().slice(0,10)})
                .then((response)=>{                     
                    this.data_=response.data.response; 
                    this.loagingC(false);
                    this.renderTable=true;
                })
                .catch(error=>{
                    console.log(error);                    
                    this.loagingC(false);                    
                }
            );               
        },
        urlDates(){
            let f_in=new Date(this.range.start).toISOString().slice(0,10);
            let f_fin=new Date(this.range.end).toISOString().slice(0,10);
           return `${process.env.APP_URL}/licencia-alcoholes-eliminados` + "/"+ f_in+"/"+f_fin;
        },
        detalleRegistros(dato){
            this.detalle_=dato;
        },
        firtsDate(){
            var date = new Date();
            var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
            return primerDia
        },
        fmoneda(value, tipo, values) {
            return Vue.filter('chartTick')(value,tipo);
        },
        loagingC(value, index, values) {
            return Vue.filter('loadingCharge')(value);
        },
        notifications(Code,Message){
            if(Code=="200"){
                Command: toastr.success(Message, "Notifications");
            }else{
                Command: toastr.warning(Message, "Notifications");
            }
        }
    },
    mounted() {
        this.loadTableSample();
    } 
  }
  </script>