<template>
    <div>
      <table class="table table-hover" v-bind:id="'sample_2'"  width="100%">
        <thead>
            <tr>
                <th>Folio ID</th>
                <th>Licencia</th>                  
                <th>RFC</th>
                <th>Estatus</th>
                <th>Fecha de Vencimiento</th>                  
                <th>Usuario</th>
                <th>Estatus Bitacora</th>
                <th>Fecha Registro</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        </tbody>             
      </table>
    </div>
  </template>
  <script>
    export default {
      name:"TableSample",
      props: {id:'',data_:[],lengthMenu:[]},
      mounted () {
        //TableManaged2.init2();
        this.createTable();
        window.tableBitacora = this;
      },      
      methods:{
        detalleRegistros(dato){
          this.$emit('guardar', dato);
        },
        estatusBitacora(e){
            let r='';
            if(e=="2"){
                r='Incidencia';
            }else if(e=="3"){
                r='Nuevo Registro';
            }else{
                r='Actualización';
            }
            return r;
        },
        createTable(){
          $('#sample_2').DataTable( {
            "data": this.data_,
              "columns": [
                {"data": "folio_id"},
                {"data": "licencia"},
                {"data": "rfc"},
                {"render": this.spanEstatus },
                {"data":"fecha_de_vencimiento"},
                {"data":"usuario"},
                {"render": this.spanEstatusBitacora }, 
                {"data":"created_at"},       
                {"render": this.btnDetalle },           
              ]
          });
        },
        btnDetalle( data, type, row, meta){
          return "<a class='btn btn-icon-only btn-circle green' data-toggle='modal' href='#portlet-edit' title='Consultar Registro'  onclick='detalleRegistros("+JSON.stringify(row)+")'><i class='fa fa-list'></i></a>"
        },
        spanEstatus( data, type, row, meta){
          let status=row.estatus==='VIGENTE' ? 'btn-success' : row.estatus==='REFERENCIA ACTIVA' ? 'btn-warning' : row.estatus==='PAGADO' ? 'label-default' : 'label-danger'
          return '<span class="label  btn-circle '+status+'" class="">'+row.estatus+' </span>';
        },
        spanEstatusBitacora( data, type, row, meta){
          let status=row.estatus_bitacora=='3' ? 'btn-info' : row.estatus_bitacora=='2' ? 'btn-warning' : row.estatus_bitacora=='4' ? 'btn-danger' :'btn-primary';
          return '<span class="label  btn-circle '+status+'" class="">'+row.estatus_descripcion+' </span>';
        }
      }        
    }
  </script>